<template>
  <el-select v-model="branchId" placeholder="请选择" @change="handleChange">
    <el-option
        v-for="branch in branches"
        :key="branch.id"
        :label="branch.name"
        :value="branch.id">
    </el-option>
  </el-select>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";

export default {
  name: "BranchSelect",
  data() {
    return {
      branchId:'',
      branches:[],
    }
  },
  methods:{
    handleChange(){
      this.$emit('parentEvent',this.branchId);
    },
    initData(checkedId){
      if(checkedId != null){
        this.branchId = checkedId;
      }
      this.$http({
        method: 'GET',
        url: UrlEnum.BRANCHES + '?page=1&size=100&orderType=asc',
        data: '',
      })
      .then((res) => {
        this.branches = res.data.list;
      })
      .catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getBranchId(){
      return this.branchId;
    },
    setBranchId(branchId){
      this.branchId = branchId;
    }
  }
}
</script>

<style scoped>

</style>
