<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogShow"
      :width="width"
      :top="top"
      :before-close="handleClose"
  >
    <iframe
        id="ifr"
        :src="editUrl" :height="height" >
    </iframe>
    <div slot="footer" class="dialog-footer" v-if="style=='edit'">
      <el-button size="small" @click="handleCancel">取 消</el-button>
      <el-button type="primary" size="small" @click="handleConfirm">确 定</el-button>
    </div>
    <div slot="footer" class="dialog-footer" v-if="style=='detail'">
      <el-button type="primary" size="small" @click="handleCancel">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "EditDialog",
  data() {
    return {
      dialogShow:false,
      height:"0px",
      width:"0px",
      editUrl:'',
      title:'',
      top:"0px",
      style:'edit'
    }
  },
  methods:{
    init(style,width,height,title,url){
      this.style=style;
      this.editUrl = url;
      this.title = title;
      this.dialogShow = true;
      if(width.toString().indexOf("%")>0){
        this.width = width;
      }
      else{
        this.width = width + "px";
      }
      if(height.toString().indexOf("%")>0){
        height = height.toString().replace("%","");
        height = (document.body.clientHeight - 160) * (height / 100);
        this.height = height  + "px";
        this.top = (document.body.clientHeight - 160 - height) / 2 + "px";
      }
      else{
        this.height = height + "px";
        this.top = (document.body.clientHeight - 160 - height) / 2 + "px";
      }
    },
    hide(){
      this.dialogShow = false;
      this.editUrl = '';
    },
    handleCancel(){
      this.dialogShow = false;
      this.editUrl = '';
    },
    handleConfirm(){
      let submit = document.getElementById('ifr').contentWindow.document.getElementById('layuiadmin-app-form-submit');
      submit.click();
    },
    handleClose(){
      this.dialogShow=false;
      this.editUrl='';
    },
  }
}
</script>

<style>
iframe{
  border: 0px;
  width:calc(100% - 1px);
}
.el-dialog__header{
  padding: 0 80px 0 20px;
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  background-color: #F8F8F8;
  border-radius: 2px 2px 0 0;
}
.el-dialog__title {
  line-height: 24px;
  font-size: 14px;
  color: #303133;
}
.el-dialog__body {
 padding: 0px !important;
}
.el-dialog__headerbtn {
  position: absolute;
  top: 15px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>
